@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('resources/icomoon/style.css');
@import url('resources/fonts/fonts.css');
/* @import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

/* Aligned centre */

@media screen and (max-width: 430px) {
    .site-grid.site-grid--support-medium,
    .interestWrap {
        margin: 24px 19px 0 21px;
        padding: 24px 16px;
        align-self: auto !important;
    }
    .font-wrap {
        display: none;
    }
}

@media screen and (min-width: 431px) and (max-width: 768px) {
    .site-grid.site-grid--support-medium {
        max-width: 462px;
        width: 462px;
    }

    .interestWrap {
        margin: 50px;
        padding: 30px 35px;
        align-self: auto !important;
    }
    .font-wrap {
        display: none;
    }
    .align-self {
        align-self: center;
    }
}

/* 60px */

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .site-grid.site-grid--support-medium {
        max-width: 462px;
        width: 462px;
    }

    .registration {
        max-width: 420px !important;
        width: 420px !important;
        padding: 42px !important;
    }
    .interestWrap {
        max-width: 724px;
        width: 724px;
        padding: 42px 60px;
        margin-top: 83px;
        margin-bottom: 124px;
    }
    .font-wrap {
        position: fixed;
        top: 341px;
        margin-left: 60px;
    }

    .form-wrap {
        margin-right: 60px;
    }
    .align-self {
        align-self: flex-end;
    }
}

/* 要大于1024 Fixed magin 150px  */
@media screen and (min-width: 1025px) {
    .site-grid.site-grid--support-medium {
        max-width: 462px;
        width: 462px;
    }
    .interestWrap {
        max-width: 724px;
        width: 724px;
        padding: 42px 60px;
        margin-top: 83px;
        margin-bottom: 124px;
    }
    .font-wrap {
        position: fixed;
        top: 341px;
        margin-left: 150px;
    }

    .form-wrap {
        margin-right: 150px;
    }
    .align-self {
        align-self: flex-end;
    }
}

@media screen and (min-width: 1441px) {
    .site-grid.site-grid--support-medium {
        max-width: 462px;
        width: 462px;
    }
    .interestWrap {
        max-width: 724px;
        width: 724px;
        padding: 42px 60px;
        margin-top: 83px;
        margin-bottom: 124px;
    }
    .font-wrap {
        position: fixed;
        top: 404px;
        margin-left: 216px;
    }

    .form-wrap {
        margin-right: 150px;
    }
    .align-self {
        align-self: flex-end;
    }
}

/* 横屏 */
.isHorizontal {
    width: auto !important;
    max-width: none !important;
    min-height: auto !important;
    height: auto !important;
    margin: 24px 19px 0 21px !important;
    align-self: auto !important;
}

.text-gradient {
    background-image: linear-gradient(45deg, #8f0044, #bc003c, #e20030, #ff1636);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
